import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheaseedUser, ContentService, FirebaseService, GroupService, SharedUserService } from '@cheaseed/cheaseed-core';
import { where } from '@angular/fire/firestore';
import { combineLatest, debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { LedgerService, PortalOfferService } from '@cheaseed/portal/util';
import { GlobalMessageComponent } from '@cheaseed/shared/ui';
import { OfferSummaryComponent } from '../offer-summary/offer-summary.component';
import { Group, LedgerEntry } from '@cheaseed/node-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cheaseed-purchase-stats',
  standalone: true,
  imports: [
    CommonModule,
    GlobalMessageComponent,
    OfferSummaryComponent,
    IonContent
  ],
  templateUrl: './purchase-stats.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseStatsComponent {
  private firebase = inject(FirebaseService)
  private userService = inject(SharedUserService)
  private ledgerService = inject(LedgerService)
  public contentService = inject(ContentService)
  private modalController = inject(ModalController)
  private offerService = inject(PortalOfferService)
  groupService = inject(GroupService)

  @Input() user: CheaseedUser | undefined
  @Input() showPurchases = true
  @Input() message = ''

  purchases$ = this.firebase.collection$(this.userService.getUserConsumablesPath(), where('isStripePortalConsumable', '==', true))
    .pipe(
      debounceTime(200),
      map(purchases => {
        purchases.sort((a, b) => a.createDate > b.createDate ? -1 : 1)
        return purchases.filter(p => p.purchased)
      }),
      shareReplay(1)
    )

  ledgerEntries$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(user => this.ledgerService.getLedgerEntriesForUser(user?.docId as string)),
      shareReplay(1)
    )

  offers$ = this.offerService.getAvailableUserPortalOffers()
    .pipe(
      tap(offers => console.log('offers', offers)))
    
  dismiss() {
    this.modalController.dismiss()
  }

  hasSeedTypes() {
    const types = this.user?.seedTypeBalance || {}
    const sum = Object.keys(types).reduce((acc, key) => acc + types[key], 0)
    return sum > 0
  }

  getSeedTypeSingular(seedType: string) {
    return this.contentService.getSingularGlobal(seedType)
  }

  getEntryDescription(item: LedgerEntry) {
    return item.description
  }
  
  async leaveGroup(user: CheaseedUser | undefined, group: Group) {
    await this.groupService.removeGroupUser(user?.docId as string, group)
  }
}
