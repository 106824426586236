import { DataSource } from "@angular/cdk/table";
import { DocumentData, QueryDocumentSnapshot, limit, orderBy, startAfter, startAt } from "@angular/fire/firestore";
import { FirebaseService } from "@cheaseed/cheaseed-core";
import { BehaviorSubject } from "rxjs";

// Implements server-side sorting and pagination and filtering

export interface FirestoreDataSourceOptions {
  path?: string
  filterKey?: string
  filter?: string
  sortKey?: string
  sortDirection?: 'desc' | 'asc'
  pageIndex?: number
  pageSize?: number
};
export class FirestoreDataSource implements DataSource<any> {

  records$ = new BehaviorSubject<any[]>([])
  loading$ = new BehaviorSubject(false)
  filter = ''
  lastOptions:FirestoreDataSourceOptions = {}

  constructor(private firebase: FirebaseService) {
    console.log("FirestoreDataSource constructor")  
  }

  connect() {
    console.log("connect")
    return this.records$
  }

  disconnect() {
    console.log("disconnect")
  }

  complete() {
    console.log("complete")
    this.records$.complete()
    this.loading$.complete()
  }

  path = '';
  // current array of start docs for pages
  displayStartDocs: QueryDocumentSnapshot<DocumentData>[] = []
  // current end doc for current page
  displayEndDoc: QueryDocumentSnapshot<DocumentData>
  // total number of records in collection
  numRecords = 0

  // https://firebase.google.com/docs/firestore/query-data/query-cursors#paginate_a_query
  
  async load(input: FirestoreDataSourceOptions) 
  {
    this.loading$.next(true)
    const options = { ...this.lastOptions, ...input }
    console.log("load options", options)

    // Reset pagination
    if (options.pageIndex === -1)
      this.displayStartDocs = []

    const pageIndex = options.pageIndex || -1
    const position =
      pageIndex === -1
        ? []
        : pageIndex < this.displayStartDocs.length
          ? [ startAt(this.displayStartDocs[pageIndex]) ] 
          : [ startAfter(this.displayEndDoc) ]
    
    const sort = options.sortKey ? [ orderBy(options.sortKey, options.sortDirection) ] : []
    const snaps = await this.firebase.getQuerySnapshotData(
      options.path,
      position,
      [ limit(options.pageSize) ],
      sort)
    if (pageIndex === -1)
      this.numRecords = await this.firebase.getCollectionCount(options.path)
    if (pageIndex >= this.displayStartDocs.length)
      this.displayStartDocs = [ ...this.displayStartDocs, snaps[0] ]
    this.displayEndDoc = snaps[snaps.length - 1]
    const recs = snaps.map(doc => ({ ...doc.data(), docId: doc.id }))
    this.records$.next(recs)
    this.lastOptions = options
    console.log("load complete", { 
      recs, 
      displayStartDocs: this.displayStartDocs, 
      displayEndDoc: this.displayEndDoc,
      numRecords: this.numRecords 
    })
    this.loading$.next(false)
  }

}