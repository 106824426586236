import { Inject, Injectable, inject } from '@angular/core'
import { CheaseedUser, FirebaseService, SharedUserService } from '@cheaseed/cheaseed-core'
import { GIFTS_COLLECTION, Gift, GiftConverter } from '@cheaseed/node-utils'
import { BehaviorSubject, Observable, combineLatest, filter, map, shareReplay, tap } from 'rxjs'
import { LedgerService } from './ledger.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  private router = inject(Router)
  firebase = inject(FirebaseService)
  ledgerService = inject(LedgerService)
  userService = inject(SharedUserService)
  waitingForAuthentication$ = new BehaviorSubject<any>(null)

  constructor(
    @Inject('environment') public environment: any,
  ) { 
      combineLatest([ this.waitingForAuthentication$, this.userService.requireLogin$, this.userService.user$ ])
        .pipe(
          filter(([ waiting, requireLogin, user ]) => !!waiting && !requireLogin && !user?.isAnonymousUser),
          takeUntilDestroyed()
        )
        .subscribe(([ waiting, requireLogin, user ]) => {
          console.log('data', waiting, requireLogin, user)
          this.waitingForAuthentication$.next(null)
          this.consumeGift(user as CheaseedUser, waiting.gift)
        })
  }

  getGift(docId: string): Observable<Gift> {
    return this.firebase.doc$(`${GIFTS_COLLECTION}/${docId}`)
      .pipe(
        map(gift => GiftConverter.fromFirestoreData(gift)),
        tap(gift => console.log('getGift', gift)),
        shareReplay(1)
      )
  }

  async checkConsumeGift(user: CheaseedUser | null, gift: Gift) {
    console.log('checkConsumeGift', user, gift)
    if (user?.isAnonymousUser) {
      this.waitingForAuthentication$.next({ gift })
      this.userService.requestLogin$.next(true)
    }
    else
      await this.consumeGift(user, gift)
  }

  getSeedTypeSummary(seedTypes: any) {
    return this.ledgerService.getSeedTypeSummary(seedTypes)
  }

  async consumeGift(user: CheaseedUser | null, gift: Gift) {
    console.log('consumeGift', user, gift)
    const now = new Date()
    const summary = this.getSeedTypeSummary(gift.metadata)
    // Add ledger entry for gift for current user
    await this.ledgerService.addUserLedgerEntry({
      createdAt: now,
      userId: user?.docId as string,
      type: 'credit',
      source: 'gift',
      origin: gift.docId,
      amount: gift.amount / 100,
      credits: gift.metadata,
      description: `Gift from ${gift.sender}${summary ? ': ' + summary : ''}`
    })
    await this.userService.incrementSeedTypeBalance(gift.metadata)
    // Mark gift as consumed
    await this.firebase.updateAt(`${GIFTS_COLLECTION}/${gift.docId}`, { consumedAt: now })
    this.router.navigate(['/home'])
  }

}