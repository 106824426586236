<ion-content>
  <div class="sticky rounded-lg top-0 p-4 pb-0 bg-gray-100">
    <div class="p-1 rounded-lg">
        <cheaseed-global 
            class="text-left text-xl font-bold" 
            key="portal.payment.dialog.title"
            default="Checkout"/>
    </div>
  </div>
  <div class="w-full border-t border-gray-300"></div>
  <ng-container *ngIf="stripeService.purchaseSession$ | async as session">
    <div class="p-6">
      @if (session.offer; as offer) {
        <div class="bg-white p-2 mb-2 rounded-lg">
          <div class="text-lg font-bold">
            {{ offer.title }}
          </div>
          <p class="text-xs sm:text-sm">{{ offer.description }}</p>
          <p>Price:
            @if (offer.specialPrice) {
              <span style="text-decoration-line: line-through; padding: 0 4px 0 8px">{{ offer.priceAsNumber | currency: 'USD' }}</span>
            }
            <span class="font-bold">{{ (offer.specialPrice || offer.priceAsNumber) | currency: 'USD' }}</span>
          </p>
        </div>
      }
      @if (session.offers) {
        @if (session.offers.length > 0) {          
          <div class="font-bold text-lg px-1 pt-2 pb-3">
            <cheaseed-global 
              key="portal.purchase.apply.offer"
              default="Apply offer (max 1):"/>
          </div>
          <div class="rounded-lg bg-white p-1">
            <ion-radio-group [allowEmptySelection]="true" (click)="offerSelected($event)" class="flex flex-col">
              @for (offer of session.offers; track offer.name) {
                <ion-radio 
                  labelPlacement="end" 
                  justify="start"
                  mode="md"
                  class="text-xs sm:text-sm bg-white px-3 py-2"
                  [value]="offer">
                    {{ offer.spec.value | currency: 'USD' }} off ({{ offer.name }})<br/>Expires {{ offer.expiresAt | date: 'shortDate' }}
                </ion-radio>
            }
            </ion-radio-group>
        </div>
        }
      }
      <ng-container *ngIf="{ amountToPay: (amountToPay$ | async) } as pay">
        @if ((pay.amountToPay || 0) > 0) {
          <div class="font-bold text-lg px-1 pt-3">
            {{ (contentService.getGlobal('portal.purchase.amountDue') || 'Amount Due:') + ' ' + (pay.amountToPay | currency: 'USD') + (this.input?.term?.discount ? ' with ' + (this.input?.term?.discount | percent) + ' discount': '')}}
          </div>
          <div [formGroup]="checkoutForm" class="my-3">
            @if (elementsOptions.clientSecret) {
              <ngx-stripe-elements
                [stripe]="ngxStripe"
                [elementsOptions]="elementsOptions"
                (load)="loadSavedState($event)">
                <ngx-stripe-payment 
                  [appearance]="appearance" 
                  [options]="options"/>
              </ngx-stripe-elements>
            }
            @else {
              <cheaseed-spinner subtitle=" "/>
            }
          </div>
        }
        @else {
          <div class="text-lg py-3">
            <cheaseed-global 
                  key="portal.purchase.nopay.message"
                  default="No additional payment required."/>
          </div>
        }
        <div class="py-4">
          <ng-container *ngTemplateOutlet="buttonbar; context: { amountToPay: pay.amountToPay, price: session.offer.priceAsNumber }"></ng-container>
        </div>
    </ng-container>
    </div>
  </ng-container>
</ion-content>

<ng-template #buttonbar let-amountToPay="amountToPay" let-price="price">
  <div class="flex flex-row items-center justify-center text-center mt-2 pt-2 font-bold text-sm">
    <button
      class="px-2 sm:px-5 py-1 bg-white text-black rounded-full hover:bg-gray-200"
      [disabled]="paying()"
      (click)="cancel()">
      CANCEL
    </button>
    <button
      class="ml-2 px-4 sm:px-5 py-1 bg-blue-700 text-white rounded-full hover:bg-blue-400"
      [disabled]="paying()"
      (click)="processPurchase(price, amountToPay)">
      @if (paying()) {
        <span>PROCESSING...</span>
      }
      @else {        
        {{ amountToPay > 0 ? 'PAY ' + (amountToPay | currency: 'USD') : 'CONFIRM' }}
      }
    </button>
  </div>
</ng-template>
