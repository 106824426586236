@if (data$ | async; as data) {
    <div class="sticky rounded-lg top-0 p-4 pb-0 bg-gray-100">
        <div class="flex flex-cols items-center">
            <cheaseed-global 
                class="text-left text-xl font-bold" 
                key="portal.purchase.dialog.title"
                default="Buy Credits"/>
        </div>
        <div *ngIf="data?.terms?.affiliateTerms?.discount" class="pb-2 text-sm">{{ data.terms.affiliateTerms?.discount | percent }} group discount will apply.</div>
        <div *ngIf="data?.terms?.unlimitedPrepaid || data?.terms?.prepaidBalance" class="pb-2 text-sm">You are a member of a prepaid group.</div>
    </div>
    <div class="w-full border-t border-gray-300"></div>
    <div class="p-6">
        <div class="flex flex-col gap-3 font-bold">
            @for (item of data.specs; track item.name) {
                <div class="grid grid-cols-[5%_65%_25%] gap-2 bg-white rounded-lg p-2 items-center">
                    <div class="material-symbols-outlined cursor-pointer justify-self-center self-center bg-gray-100 text-[16px] sm:text-xl" (click)="item.showDescription = !item.showDescription">
                        {{ item.showDescription ? 'remove' : 'add' }}
                    </div>
                    <div class="flex flex-col">
                        <div class="flex gap-2 items-center">
                            <div class="text-[16px] sm:text-xl">{{ item.title }}</div>                    
                        </div>
                        <div *ngIf="item.showDescription" class="text-xs font-normal pb-2">{{ item.description }}</div>
                    </div>
                    <div class="flex flex-col items-center justify-center text-sm font-semibold">                
                        <div class="flex items-center justify-ceter w-full h-9 text-center rounded-md bg-chea-purple hover:bg-indigo-500 cursor-pointer"
                            (click)="purchase(data.user, data.offers, item, data.terms)">
                            <div class="text-white w-full">
                                <span class="hidden sm:inline">Buy </span><span>{{ (item.specialPrice || item.price) | currency: 'USD' }}</span>
                            </div>
                        </div>
                        @if (item.specialPrice) {
                            <div class="text-xs text-center">
                                <span class="hidden">Normally </span><span class="line-through">{{ item.price | currency: 'USD' }}</span>
                            </div>
                        }
                    </div>        
                </div>
            }   
        </div>
            <div class="p-1 pt-3">
                <cheaseed-offer-summary [offers]="data.offers" [pricingSpecs]="data.specs"/>
            </div>
    </div>
}
@else {
    <cheaseed-spinner/>
}
