import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator'
import { MatSort, MatSortModule } from '@angular/material/sort'
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { format } from 'date-fns'
import { ColSpec, SourceSpec, TIMESTAMP_MILLIS_ISO_FORMAT, TIMESTAMP_SECS_FORMAT, stringifyKeysInOrder, upperFirst } from '@cheaseed/node-utils'
import { FirebaseService } from '@cheaseed/cheaseed-core'

import { marked } from 'marked'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { FirestoreDataSource, FirestoreDataSourceOptions } from './firestore-data-source'
import { MatSnackBar } from '@angular/material/snack-bar'
import { CommonModule } from '@angular/common';
import { IonContent, IonIcon, IonModal, IonSearchbar } from '@ionic/angular/standalone';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'cheaseed-spec-table',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    IonIcon,
    IonSearchbar,
    IonModal,
    IonContent,
    ClipboardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTableModule,
    MatMenuModule,
    MatSortModule,
    MatPaginatorModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './spec-table.component.html',
  styleUrls: ['./spec-table.component.scss'],
})
export class MatTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() deleted = new EventEmitter();

  // Expanded rows:
  // https://stackblitz.com/edit/generic-mat-table
  // https://stackblitz.com/edit/angular-material-expandable-table-rows
  observableData: any; //observable
  displayedColumns: string[];
  stmtDocId: string;
  isAdd = true;
  @Input() tableSpec: any;
  @Input() context: any;
  @Input() loading = false;
  @Input() title: string;
  @Input() searchbar = false;
  @Input() filterValue: string;
  @Output() filterChanged = new EventEmitter<any>()
  public pageSize: number;

  firestoreDataSource: FirestoreDataSource;
  matTableDataSource: MatTableDataSource<any>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  selectedRow: any
  @Input() chatDocId: string
  inspectDialogOpened: any
  columnInspect$ = new BehaviorSubject<any>(null)
  @Input() sourceSpec: SourceSpec

  // Necessary for dynamic update of data in table
  @Input() set data(values: any[]) {
    if (values) {
      // console.log('set data', values)
      const source = new MatTableDataSource<any>()
      source.data = values
      if (this.tableSpec.sortingDataAccessor)
        source.sortingDataAccessor = this.tableSpec.sortingDataAccessor
      source.paginator = this.paginator
      source.sort = this.sort
      this.matTableDataSource = source
    }
  }

  constructor(
    private clipboard: Clipboard,
    private snackbar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private firebase: FirebaseService)
    {
      marked.setOptions({
        pedantic: false,
        gfm: true,
        breaks: false
      })
      this.pageSize = parseInt(localStorage.getItem('pageSize') || '10')
    }

  async ngOnInit() {
    // console.log('ngOnInit')
    if (this.sourceSpec) {
      const spec = this.sourceSpec
      this.firestoreDataSource = new FirestoreDataSource(this.firebase)
      const input: FirestoreDataSourceOptions = 
        {
          path: await firstValueFrom(spec.path),
          filterKey: spec.filterKey,
          filter: this.filterValue,
          sortKey: spec.sortKey,
          sortDirection: spec.sortDirection,
          pageIndex: -1,
          pageSize: this.pageSize
        }
      await this.firestoreDataSource.load(input)
    }
    this.buildDisplayedColumns()
    console.log("pageSize", this.pageSize)
  }

  ngAfterViewInit() {
    this.setFilter(this.filterValue)
    // console.log('ngAfterViewInit', this.paginator, this.sort)
    if (this.matTableDataSource) {
      this.matTableDataSource.paginator = this.paginator
      this.matTableDataSource.sort = this.sort
      if (this.tableSpec.sortingDataAccessor)
        this.matTableDataSource.sortingDataAccessor = this.tableSpec.sortingDataAccessor
    }

    // reset the paginator after sorting
    this.sort.sortChange
      .subscribe(data => console.log("sortChange", data))
      // this.paginator.pageIndex = 0);
      // this.loadLessonsPage()) 
  }

  handlePageEvent(ev: PageEvent) {
    // console.log(ev, this.paginator)
    if (this.firestoreDataSource) {
      // TODO: The paginator for this datasource doesn't work properly. The pageIndex is always 1.
      // I will test this again after we upgrade to latest Angular Material
      // 2023-10-05
      const sz = this.pageSize
      this.firestoreDataSource.load({
          filter: this.filterValue,
          pageIndex: ev.pageSize !== sz ? -1 : ev.pageIndex, 
          pageSize: ev.pageSize
      })
      if (ev.pageSize !== sz) {
        console.log('pageSize changed')
        this.paginator.pageIndex = 0
      }
      // else
      //   this.paginator.pageIndex = ev.pageIndex
    }
    console.log('handlePageEvent', ev, this.pageSize )
    this.pageSize = ev.pageSize
    localStorage.setItem('pageSize', `${ev.pageSize}`)
  }

  ngOnDestroy() {
    // console.log('ngOnDestroy')
    if (this.firestoreDataSource)
      this.firestoreDataSource.complete()
  }

  setFilter(value: string) {
    if (this.matTableDataSource)
      this.matTableDataSource.filter = value
  }

  onContextMenu(event: MouseEvent, row: any, add: boolean) {
    console.log('onContextMenu', event, row);
    // event.preventDefault();
    // this.isAdd = true;
    // this.contextMenuPosition.x = event.clientX - 280 + 'px';
    // this.contextMenuPosition.y = event.clientY + 'px';
    // this.contextMenu.menuData = { item: row };
    // this.contextMenu.menu.focusFirstItem('mouse');
    // this.contextMenu.openMenu();
  }

  buildDisplayedColumns() {
    this.displayedColumns = this.tableSpec.columns
      .filter(tc => !tc.displayIf || tc.displayIf(this.context))
      .map(tc => tc.key)
    if (this.tableSpec.deleteRow) {
      this.displayedColumns.unshift('delete');
    }
    if (this.tableSpec.inspectRow) {
      this.displayedColumns.unshift('inspect');
    }
    if (this.tableSpec.editRow) {
      this.displayedColumns.unshift('edit');
    }
    // console.log('displayed columns', this.displayedColumns)
  }

  getColumnValue(column:any, item: any) {
    const val = this.getRawColumnValue(column, item)
    const strval = this.getStrColumnValue(column, item)
    // console.log(val, strval)
    try {
    return column.timestamp 
      ? (val ? format(val.toDate(), TIMESTAMP_SECS_FORMAT) : null)
      : column.timestamp_in_ms_utc
        ? (val ? val.toDate().toISOString() : null)
        : column.timestamp_in_ms
          ? (val ? format(val.toDate(), TIMESTAMP_MILLIS_ISO_FORMAT) : null)
          : column.json
            ? strval
            : column.checkbox
              ? val ? '✅' : ''
              : column.valuefunc
                ? column.valuefunc(val, item)
                : (column.truncated && !!strval)
                  ? (strval.slice(0, 30) + (strval.length > 30 ? '...' : ''))
                  : strval
    }
    catch (e) {
      console.error('getColumnValue', column, item, e)
      return val
    }
  }

  getStrColumnValue(column: ColSpec, item: any) {
    const val = this.getRawColumnValue(column, item)
    return typeof val !== 'string' 
      ? JSON.stringify(val, null, 2) 
      : val
  }

  getPopupColumnValue(column: ColSpec, item: any) {
    const val = this.getRawColumnValue(column, item)
    return column.markdown
      ? this.renderMarkdown(val)
      : this.getStrColumnValue(column, item)
  }

  renderMarkdown(text: string) {
    const block = (marked(text) as string).trim() 
    return block ? this.sanitizer.bypassSecurityTrustHtml(block) : null
  }

  getRawColumnValue(column: ColSpec, item: any) {
    let val = undefined
    // Handle dot notation keys
    for (const k of column.key.split('.'))
        val = val ? val[k] : item[k]
    return val    
  }

  upperFirst(s) {
    return upperFirst(s)
  }

  clickRow(row) {
    console.log('row', row);
    this.selectedRow = row;
    return this.tableSpec.clickRow ? this.tableSpec.clickRow(row) : null;
  }

  openTextDialog(row): void {
    this.columnInspect$.next(
      stringifyKeysInOrder(row))
  }

  doFilter(value: string) {
    const str = value.trim().toLowerCase()
    this.setFilter(str)
    this.filterChanged.emit( { filter: str } )
  }

  copyToClipboard(value: string) {
    // console.log('copyToClipboard', value)
    this.clipboard.copy(value)
    this.snackbar.open('Copied to clipboard.', 'Cancel', { duration: 2000 } )
  }
}