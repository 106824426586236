<ngx-file-drop
  dropZoneLabel="Drop files here"
  dropZoneClassName="dropzone"
  contentClassName="contentzone"
  (onFileDrop)="dropped($event)"
  (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)">
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector">
    <div class="flex justify-between items-center">
      <div>Drop files here</div>      
      <button mat-raised-button (click)="openFileSelector()">Upload</button>
    </div>
  </ng-template>
</ngx-file-drop>