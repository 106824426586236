<ion-content>
    <div class="flex flex-col gap-4 p-8 overflow-auto">
        <div class="flex gap-4 items-center w-full justify-end">
            <img class="h-8 w-8 rounded-full" [src]="user?.photoURL" alt="">
            <div>
                <div>{{ user?.docId }}</div>
                @if (groupService.currentUserGroup(); as group) {
                    @if (group.name) {
                        <div class="text-xs">
                            {{ group.name }} 
                            <a class="text-blue-500 underline" (click)="leaveGroup(user, group)">
                                Leave
                            </a>
                        </div>                               
                    }
                }
            </div>
        </div>
        @if (message) {
            <div class="text-chea-purple font-bold">
                <p>{{ message }}</p>
            </div>
        }
        @if (hasSeedTypes()) {
            <h1 class="text-xl font-bold">My Credits</h1>
            <div class="flex flex-col">
                @for (item of (user?.seedTypeBalance | keyvalue); track item.key) {
                    <div *ngIf="item.value > 0">{{ getSeedTypeSingular(item.key) }}: {{ item.value }}</div>
                }
            </div>    
        }
        @else {
            <h1 class="text-lg">
                <cheaseed-global key="profile.purchaseStats.noCredits" default="You have no remaining credits."/>
            </h1>
        }
        @if (offers$ | async; as offers) {
            @if (offers.length > 0) {
                @if (contentService.pricingSpecs$ | async; as pricingSpecs) {                 
                    <div class="bg-slate-200 p-2 rounded-lg">
                        <cheaseed-offer-summary [offers]="offers" [pricingSpecs]="pricingSpecs"/>
                    </div>
                }
            }
        }
        @if (showPurchases) {
            @for (item of ledgerEntries$ | async; track item.docId) {
                <div class="border-2 p-2 text-xs">
                    <div>
                        {{ item.createdAt | date: 'short' }} {{ item.amount && item.source !== 'gift' ? ': ' + (item.amount | currency: 'USD') : ''}}
                    </div>
                    <div>
                        {{ item.description }}
                    </div>
                </div>
            }
        }
        <button class="bg-chea-purple text-white w-40 h-8 self-center rounded-lg" (click)="dismiss()">OK</button>
    </div>    
</ion-content>