export interface JsonFormValidators {
    min?: number;
    max?: number;
    required?: boolean;
    requiredTrue?: boolean;
    email?: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    nullValidator?: boolean;
  }
  export interface JsonFormControlOptions {
    min?: string;
    max?: string;
    step?: string;
    icon?: string;
  }

  export interface JsonFormChoice {
    label?: string;
    value: string;
  }

  export interface JsonFormControl {
    name: string;
    label: string;
    value?: string;
    cssClass?: string;
    readonly?: boolean;
    placeholder?: string;
    type: 'text' | 'textarea' | 'email' | 'checkbox' | 'select' | 'radio' | 'range' | 'toggle' | 'select' | 'radio' | 'password' | 'number' | 'search' | 'tel' | 'url' | 'date' | 'currency' | 'buttons' | 'static';
    choices?: JsonFormChoice[];
    options?: JsonFormControlOptions;
    required?: boolean;
    helperText?: string;
    errorText?: string;
    infoText?: string;
    validators?: JsonFormValidators;
    visible?: (form: any) => boolean;
    disable?: (form: any) => boolean;
    click?: (event: any) => void;
  }
  export interface JsonFormData {
    submitAlwaysEnabled?: boolean;
    controls: JsonFormControl[];
  }