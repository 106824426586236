import { Route } from '@angular/router';
import { DevGuard } from './dev.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadComponent: () =>
      import('@cheaseed/portal/home').then((m) => m.HomeComponent),
  },
  {
    path: 'landing',
    loadComponent: () =>
      import('@cheaseed/portal/home').then((m) => m.LandingComponent),
  },
  {
    path: 'landing/:image',
    loadComponent: () =>
      import('@cheaseed/portal/home').then((m) => m.LandingComponent),
  },
  {
    path: 'advanced',
    loadComponent: () =>
      import('@cheaseed/portal/home').then((m) => m.AdvancedComponent),
  },
  {
    path: 'reports',
    loadComponent: () =>
      import('@cheaseed/portal/reports').then((m) => m.ReportsComponent),
    canActivate: [DevGuard]
  },
  {
    path: 'conversation/:conversationId',
    loadComponent: () =>
      import('@cheaseed/portal/chat').then((m) => m.ConversationComponent),
    canActivate: [DevGuard]
  },
  {
    path: 'chat-dispatcher/:chatName',
    loadComponent: () =>
      import('@cheaseed/portal/chat').then((m) => m.ChatDispatcherComponent),
    canActivate: [DevGuard]
  },
  {
    path: 'report/:entryId',
    loadComponent: () =>
      import('@cheaseed/portal/reports').then((m) => m.ReportViewComponent),
  },
  {
    path: 'gift',
    loadComponent: () =>
      import('@cheaseed/portal/home').then((m) => m.GiftComponent),
  },
  {
    path: 'offer/:code',
    loadComponent: () =>
      import('@cheaseed/portal/home').then((m) => m.OfferLandingComponent),
  },
  {
    path: 'groups',
    loadComponent: () =>
      import('@cheaseed/portal/groups').then((m) => m.GroupsComponent),
  },
  {
    path: 'group/:groupId',
    loadComponent: () =>
      import('@cheaseed/portal/groups').then((m) => m.EditGroupComponent),
  },
  {
    path: 'group-invite/:groupId',
    loadComponent: () =>
      import('@cheaseed/portal/groups').then((m) => m.GroupInviteComponent),
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];