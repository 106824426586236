<div *ngIf="delay$ | async" class="w-full flex flex-col items-center justify-center text-center">
    <mat-spinner class="chea-mat-spinner"></mat-spinner>
    <div *ngIf="title" class="p-4 pb-1 lg:px-20 text-chea-purple text-xl lg:text-2xl">{{ title }}</div>
    @if (!!subtitle) {
        <div class="w-72 sm:w-96 py-1 px-3 text-black font-bold text-base lg:text-xl">{{ subtitle }}</div>
    }
    @else {
        <ng-container *ngIf="autoSubtitle$ | async as subtitle">
            <div class="w-72 sm:w-96 py-1 px-3 text-black font-bold text-base lg:text-xxl">{{ subtitle }}</div>
        </ng-container>
    }
</div>