import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '@cheaseed/cheaseed-core';
import { filter, map, shareReplay } from 'rxjs';
import { marked } from 'marked'

@Component({
  selector: 'cheaseed-global',
  standalone: true,
  imports: [ CommonModule ],
  template: `<div [innerHtml]="result$ | async"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalMessageComponent {

  @Input() key: string|null = null
  @Input() default?: string
  @Input() args?: (string | undefined)[]
  @Input() sanitize? = true
  @Input() markdown? = false

  contentService = inject(ContentService)
  sanitizer = inject(DomSanitizer)

  result$ = this.contentService.loader$
    .pipe(
      filter(loaded => !!loaded),
      map(() => {
        let result = this.contentService.getGlobal(this.key as string) || this.default || this.key
        if (result && this.args) {
          this.args.forEach((arg, index) => {
            result = result.replace(/\$\w+/, arg)
          })
        }
        result = this.markdown ? marked(result) : result
        return this.sanitize ? this.sanitizer.bypassSecurityTrustHtml(result) : result  
      }),
      shareReplay(1)
    )
}
