<div class="grid grid-cols-[5%_65%_25%] gap-2 bg-white rounded-lg p-2 items-center">
    <div class="material-symbols-outlined cursor-pointer justify-self-center self-center bg-gray-100 text-[16px] sm:text-xl" (click)="item.showDescription = !item.showDescription">
        {{ item.showDescription ? 'remove' : 'add' }}
    </div>
    <div class="flex flex-col">
        <div class="flex gap-2 items-center">
            <div class="text-[16px] sm:text-xl">{{ item.title }}</div>                    
        </div>
        <div *ngIf="item.showDescription" class="text-xs font-normal pb-2">{{ item.description }}</div>
    </div>
    <div class="flex flex-col items-center justify-center text-sm font-semibold">                
        <div class="flex items-center justify-ceter w-full h-9 text-center rounded-md bg-chea-purple hover:bg-indigo-500 cursor-pointer"
            (click)="purchase.emit(item)">
            <div class="text-white w-full">
                <span class="hidden sm:inline">Buy </span><span>{{ (item.specialPrice || item.price) | currency : 'USD' : 'symbol' : currencyDigitInfo 
                }}</span>
            </div>
        </div>
        @if (item.specialPrice) {
            <div class="text-xs text-center">
                <span class="hidden">Normally </span><span class="line-through">{{ item.price | currency: 'USD' : 'symbol' : currencyDigitInfo }}</span>
            </div>
        }
    </div>        
</div>