import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContentService } from '@cheaseed/cheaseed-core';
import { map, switchMap, tap, timer } from 'rxjs';

@Component({
  selector: 'cheaseed-spinner',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './labelled-spinner.component.html',
})
export class LabelledSpinnerComponent {
  @Input() title:string|undefined = undefined
  @Input() subtitle:string|undefined = undefined

  contentService = inject(ContentService)
  
  autoSubtitle$ = this.contentService.globals$
    .pipe(
      // tap(data => console.log('globals', data)),
      switchMap(() => timer(10)), // delay for 1 sec to avoid text flicker
      map(() => this.contentService.currentSpinnerMessage()),
    )

  delay$ = timer(300).pipe(map(() => true))

}
