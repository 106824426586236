<div *ngIf="firestoreDataSource?.loading$ | async; else bulkdata">
    <mat-spinner class="chea-mat-spinner"></mat-spinner>
  </div>
  <ng-template #bulkdata>
    <div style="margin: 20px">
      <div *ngIf="title" class="px-0 py-2 text-2xl">{{ title }}</div>
      <div *ngIf="searchbar" style="margin: 20px 0 20px 0">
        <ion-searchbar 
          [debounce]="500" 
          [value]="filterValue" 
          (ionInput)="doFilter($event.target.value)">
        </ion-searchbar>
      </div>
      <button
        mat-icon-button
        *ngIf="tableSpec.addRow"
        [routerLink]="tableSpec.addRow()"
        class="btn btn-sm btn-success mb-2">
        <mat-icon>add</mat-icon>
      </button>
      <div style="overflow-x:auto;" class="mat-elevation-z8">
        <table mat-table [dataSource]="matTableDataSource || firestoreDataSource" matSort>
          <!-- Define inspectRow -->
          <ng-container *ngIf="tableSpec.inspectRow" matColumnDef="inspect">
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let item">
              <button mat-icon-button (click)="openTextDialog(item)">
                <mat-icon class="material-icons-outlined">visibility</mat-icon>
              </button>
            </td>
          </ng-container> 
          <!-- Define all columns -->
          <ng-container
            *ngFor="let column of tableSpec.columns"
            [matColumnDef]="column.key">
            <ng-container *ngIf="column.displayIf ? column.displayIf(context) : true">
              <ng-container *ngIf="column.noSort; else sortableHeader">
                <th mat-header-cell *matHeaderCellDef>
                  {{ column.label || upperFirst(column.key) }}
                </th>
              </ng-container>
              <ng-template #sortableHeader>
                <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.key }}" [class.mat-sort-header]="!column.noSort">
                  {{ column.label || upperFirst(column.key) }}
                </th>
              </ng-template>
              <td mat-cell *matCellDef="let item" [style]="column.style || tableSpec.columnStyle">
                <ng-container *ngIf="column.icon">
                  <ng-container *ngIf="column.menuButtons; else none">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>{{ column.icon }}</mat-icon>
                      <mat-menu #menu="matMenu" yPosition="below">
                        <ng-template matMenuContent>
                          <ng-container *ngFor="let button of column.menuButtons">
                            <button mat-menu-item *ngIf="button.displayFunc ? button.displayFunc(item) : true" (click)="button.action(item)">
                              {{ button.labelfunc ? button.labelfunc(item) : button.label }}
                            </button>
                          </ng-container>  
                        </ng-template>
                      </mat-menu>
                    </button>
                  </ng-container>
                  <ng-template #none>
                    <mat-icon>{{ column.icon }}</mat-icon>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.linkToFunc; else nolink">
                  <a [routerLink]="column.linkToFunc(item)" [style]="tableSpec.columnStyle">
                    {{ getColumnValue(column, item) }}
                  </a>
                </ng-container>
                <ng-template #nolink>
                  <ng-container *ngIf="column.browseToFunc; else nobrowse">
                    <ng-container *ngIf="column.browseToFunc(item[column.key], item) as browse">
                      <a [href]="browse.href" target="_blank">{{ browse.label }}</a>
                    </ng-container>
                  </ng-container>
                </ng-template>
                <ng-template #nobrowse>
                  <ng-container *ngIf="column.truncated || column.json || column.markdown; else defaultCol">
                    <!-- <div [matTooltip]="getRawColumnValue(column, item)" matTooltipPosition="below">
                      {{ getColumnValue(column, item) }}
                    </div> -->
                    <a (click)="this.columnInspect$.next(getPopupColumnValue(column, item))">
                      {{ getColumnValue(column, item) }}
                    </a>
                  </ng-container>
                </ng-template>
                <ng-template #defaultCol>
                    {{ getColumnValue(column, item) }}
                </ng-template>
              </td>
            </ng-container>
          </ng-container>
          <!-- Render row header and row -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ highlighted: row === selectedRow }"
            (contextmenu)="onContextMenu($event, row, false)"
            (click)="clickRow(row)"
          >
          </tr>
        </table>
      </div>
      <div
        style="visibility: hidden; position: fixed"
        [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y"
        [matMenuTriggerFor]="contextMenu"
      ></div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
          <ng-container *ngFor="let button of tableSpec.contextMenuButtons">
            <button mat-menu-item (click)="button.action(item)">
              {{ button.label }}
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>
      <mat-paginator
        *ngIf="!tableSpec.noPaginator"
        showFirstLastButtons
        [length]="matTableDataSource?.data.length || firestoreDataSource?.numRecords"
        [pageSize]="pageSize"
        [pageSizeOptions]="tableSpec.pageSizeOptions || [5, 10, 25, 50, 100, 250]"
        (page)="handlePageEvent($event)">
      </mat-paginator>
    </div>
  </ng-template>
  
  <ion-modal #columnInspectorModal
    *ngIf="columnInspect$ | async as value"
    [isOpen]="!!value" 
    [canDismiss]="true"
    (didDismiss)="this.columnInspect$.next(null)">
    <ng-template>
      <ion-content scrollY="true">
        <div class="alert-modal">
          <button class="copy-button" [cdkCopyToClipboard]="value" (click)="copyToClipboard(value); $event.stopPropagation()">
            <ion-icon size="large" name="copy-outline"></ion-icon>
          </button>
          <div class="close-button" (click)="columnInspectorModal.dismiss()">
            <ion-icon name="close-outline"></ion-icon>
          </div>
          <!-- <textarea
                matInput 
                cdkTextareaAutosize
                readonly="true"
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="20"              
                [value]="value"></textarea> -->
          <div class="content h-full overflow-scroll whitespace-pre-wrap select-all" [innerHTML]="value"></div>
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>