import { Inject, Injectable, inject } from '@angular/core'
import { where } from '@angular/fire/firestore'
import { ContentService, FirebaseService, SharedUserService } from '@cheaseed/cheaseed-core'
import { CheaseedEnvironment, GroupLedgerEntry, LedgerEntry } from '@cheaseed/node-utils'
import { debounceTime, firstValueFrom, map, shareReplay } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  firebase = inject(FirebaseService)
  contentService = inject(ContentService)
  private userService = inject(SharedUserService)
  
  constructor(
    @Inject('environment') public environment: CheaseedEnvironment,
  ) { }

  getLedgerEntriesForUser(userId: string) {
    const path = this.userService.getUserLedgerPath(userId)
    return this.firebase.collection$(path, where('userId', '==', userId))
      .pipe(
        debounceTime(200),
        map(entries => entries.map((entry:any) => {
          entry.createdAt = entry.createdAt.toDate()
          return entry as LedgerEntry
        })),
        map((entries:LedgerEntry[]) => entries.toSorted((a, b) => a.createdAt < b.createdAt ? 1 : -1)),
        shareReplay(1)
      )
  }

  async computeCurrentBalanceForUser(userId: string) {
    const entries = await firstValueFrom(this.getLedgerEntriesForUser(userId))
    return entries.reduce((acc, entry) => entry.type === 'credit' ? acc + entry.amount : acc - entry.amount, 0)
  }

  async addUserLedgerEntry(entry: LedgerEntry) {
    return await this.userService.addUserLedgerEntry(entry)
  }

  getSeedTypeSummary(seedTypes: any) {
    return Object.keys(seedTypes).map(key => this.contentService.getSingularGlobal(key) + ': ' + seedTypes[key])
            .toSorted()
            .join(', ')
  }
}