import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import {
  RouteReuseStrategy,
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router'
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app'
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions'
import { initializeFirestore, connectFirestoreEmulator, provideFirestore } from '@angular/fire/firestore'
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage'
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAuth, getAuth } from '@angular/fire/auth'
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment'
import { PatchLevel, ReleaseTag } from 'releasetag'
import { BuildTag } from 'buildtag'
import { AirtableBase, ExtractService, HandlebarsBase, STRIPE_TEST_PUBLISHABLE_KEY } from '@cheaseed/node-utils';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { provideIonicAngular, IonicRouteStrategy } from '@ionic/angular/standalone';
import { NgxStripeModule } from 'ngx-stripe';
import { GlobalErrorHandler } from '@cheaseed/cheaseed-core';
import { CurrencyPipe } from '@angular/common';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { PixelModule } from 'ngx-multi-pixel/pixel'
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HttpErrorInterceptor } from '@cheaseed/shared/util';

export const appConfig: ApplicationConfig = {
  providers: [
    CleverTap,
    SocialSharing,
    provideAnimations(),
    provideHttpClient(),
    // Ionic standalone https://ionicframework.com/docs/angular/build-options#standalone-based-applications
    provideIonicAngular({ innerHTMLTemplatesEnabled: true }),
    { provide: CurrencyPipe, useClass: CurrencyPipe},
    //{ provide: ModalController, useClass: ModalController},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true });
      if (environment.useEmulators) {
          connectFirestoreEmulator(firestore, 'localhost', 9198);
      }
      return firestore;
    }),
   provideStorage(() => {
        const storage = getStorage();
        if (environment.useEmulators) {
            connectStorageEmulator(storage, 'localhost', 9199);
        }
        return storage;
    }),
    provideFunctions(() => {
        const functions = getFunctions();
        if (environment.useEmulators) {
            connectFunctionsEmulator(functions, 'localhost', 9200);
        }
        return functions;
    }),
    importProvidersFrom(NgxStripeModule.forRoot(STRIPE_TEST_PUBLISHABLE_KEY)),
    importProvidersFrom(ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: false,
      manualIdle: true,
    })),
    importProvidersFrom(PixelModule.forRoot({ 
       enabled: true, 
       pixelId: [environment.metaPixelId] 
    })),
    importProvidersFrom(GoogleTagManagerModule.forRoot({
        id: environment.gtmId 
    })),
    importProvidersFrom(NgxFileDropModule),
    { provide: 'environment', useValue: { ...environment, buildTag: BuildTag, releaseTag: ReleaseTag, patchLevel: PatchLevel } },
    { provide: 'AirtableService', useClass: AirtableBase },
    { provide: 'HandlebarsBase', useClass: HandlebarsBase},
    { provide: 'ExtractService', useClass: ExtractService },
    { provide: 'UtilityService', useClass: PortalUtilityService },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}
  ]
}