import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment'

// Disable console.log in production, but allow warn and error
if (environment.production)
  window.console.log = () => { null }

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
