// import 'zone.js';

import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Component, Inject, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { Appearance, PaymentIntent, StripePaymentElementOptions } from '@stripe/stripe-js';
import {
  injectStripe,
  StripeElementsDirective,
  StripePaymentElementComponent,
  StripeCardNumberComponent,
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardGroupDirective,
  StripeServiceInterface
} from 'ngx-stripe';
import { Stripe } from 'stripe'
import { 
  AFFILIATE_TERMS, 
  AffiliateTerms, 
  CheaseedEnvironment, 
  getStripePaymentMethodConfigId, 
  getStripePublishableKey, 
  getStripeSecretKey, 
  Group, 
  GroupLedgerEntry, 
  LedgerEntry, 
  ProductPricingOffer, 
  UserPortalOffer } from '@cheaseed/node-utils';
import { 
  CheaseedStripeService, 
  CheaseedUser, 
  ContentService, 
  GroupService, 
  SharedUserService, 
  StripeInputInterface } from '@cheaseed/cheaseed-core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { LabelledSpinnerComponent } from '../labelled-spinner/labelled-spinner.component';
import { GlobalMessageComponent } from '../global-message/global-message.component';
import { LedgerService, PortalOfferService } from '@cheaseed/portal/util';
import { IonContent, IonItem, IonList, IonListHeader, IonRadio, IonRadioGroup } from '@ionic/angular/standalone';

@Component({
  selector: 'cheaseed-stripe-card',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    StripeCardNumberComponent,
    StripeCardCvcComponent,
    StripeCardExpiryComponent,
    StripeCardGroupDirective,
    LabelledSpinnerComponent,
    GlobalMessageComponent,
    IonContent,
    IonList,
    IonListHeader,
    IonRadioGroup,
    IonRadio,
    IonItem
  ],
  templateUrl: './stripe-card.component.html',
})
export class CheaseedStripeCardComponent implements OnInit {

  userService = inject(SharedUserService)
  stripeService = inject(CheaseedStripeService)
  contentService = inject(ContentService)
  private offerService = inject(PortalOfferService)
  private ledgerService = inject(LedgerService)
  private readonly fb = inject(FormBuilder);
  private groupService = inject(GroupService)
  private stripe: Stripe
  ngxStripe: StripeServiceInterface

  @Input() input: StripeInputInterface | null = null
  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;

  checkoutForm = this.fb.group({
    amount: [199, [Validators.required, Validators.pattern(/\d+/)]],
  })
  paying = signal(false);
  amountToPay$ = new BehaviorSubject<number>(0)
  selectedPortalOffer$ = new BehaviorSubject<UserPortalOffer | null>(null)

  // TODO - move these to CSS
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  }
  
  appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#673ab7',
      fontSizeBase: '12px',
      fontSizeSm: '10px'
    }
  }

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: this.appearance
  }

  options:StripePaymentElementOptions = {
    terms: { card: 'never' }
  }
  paymentIntentId: string | undefined

  constructor(
    @Inject('environment') private environment: CheaseedEnvironment,
    @Inject('UtilityService') private utilityService: any)
  {
    this.stripe = new Stripe(getStripeSecretKey(environment.production ? "prod": "dev"))
    this.ngxStripe = injectStripe(getStripePublishableKey(environment.production ? "prod": "dev"));
  }

  async ngOnInit() {    
    console.log('In stripe card component', this.input)
    const amount = this.computeAmount(this.input?.offer as ProductPricingOffer)
    this.amountToPay$.next(amount)
    await this.preparePaymentIntent(amount)
  }

  private computeAmount(offer: ProductPricingOffer) {
    console.log('computeAmount', this.input)
    const amountValue = offer?.price.slice(1)
    if (!amountValue || Number(amountValue) < 0) {
      console.error('Invalid amount specified', amountValue)
      throw new Error(`Invalid amount specified ${amountValue}`)
    }
    return this.applyDiscount(Number(amountValue))
  }

  private applyDiscount(amount: number) {
    const terms = this.input?.term as AffiliateTerms
    const discount = terms?.discount || 0
    return amount - (amount * discount)
  }

  async preparePaymentIntent(amount: number) {
    try {
      const res = await this.createPaymentIntent(Math.round(amount * 100))
      if (res.status === 'succeeded' || res.status === 'requires_payment_method') {
        this.elementsOptions.clientSecret = res.client_secret as string
        //this.utilityService.notify({ header: 'Payment', message: 'Payment Succeeded' })
        console.log('Payment Intent creation success', res)
        this.paymentIntentId = res.id
      }
      else
        throw new Error(JSON.stringify(res))
    }
    catch (e) {
      this.utilityService.notify({ message: `Payment Setup Failed with error` })
      console.error('Payment Setup Failed', e)
    }
  }

  async fetchUpdates() {
    // only fetch updates if the user has accepted to have his
    // payment info saved
    if(this.input?.user?.stripePaymentInfoSaved) {
      console.log('Fetching updates for paymentElement')
      const updateResponse = await firstValueFrom(this.paymentElement.fetchUpdates())
      console.log('Response from fetchUpdates', updateResponse)
      return updateResponse
    }
    return null
  }

  async loadSavedState(event: any) {
    console.log('In load saved state', event)
    const res = await this.fetchUpdates()
    console.log('loadSavedState', res)
  }
  
  async createPaymentIntent(amount: number) {
    const u = this.input?.user as CheaseedUser
    let stripeUserCreateRes
    const basicUser: any = {
      name: u.name,
      lastLogin: u.lastLogin,
      buildTag: u.buildTag,
      releaseTag: u.releaseTag,
      seedBalance: u.seedBalance,
      isAnonymousUser: u.isAnonymousUser,
      timezone: u.timezone
    }
    if(u.patchLevel) {
      basicUser.patchLevel = u.patchLevel
    }
    console.log('stripeCustomerId', u.stripeCustomerId)
    if (!u.stripeCustomerId) {
      //TODO - need to update delete user to delete stripe customer ??
      // Maybe we shouldnt since we will lose all transaction data ?
      // Stripe has a limit on the value of the metadata key value pair
      // so we send a subset of the user object
      stripeUserCreateRes = await this.stripe.customers.create(
        {
          email: u.docId,
          name: u.name,
          metadata: {
            user: JSON.stringify(basicUser)
          }
        })
      u.stripeCustomerId = stripeUserCreateRes.id
      await this.userService.setStripeCustomerId(stripeUserCreateRes.id)
    }
    else {
      // update the user in stripe since his info might have changed.
      await this.stripe.customers.update(u.stripeCustomerId as string, {
        email: u.docId,
        name: u.name as string,
        metadata: {
          user: JSON.stringify(basicUser)
        }
      })
    }

    console.log(`Creating payment intent`)
    const env = this.environment.production ? 'prod': 'dev'
    //console.log('Amount', amount)
    //console.log('Offer', this.input?.offer)
    const res = await this.stripe.paymentIntents.create(
      {
        amount,
        currency: 'usd',
        //payment_method_types: ['card'],
        //automatic_payment_methods: { enabled: true },
        confirmation_method: 'automatic',
        customer: u.stripeCustomerId,
        setup_future_usage: 'off_session',
        payment_method_configuration: getStripePaymentMethodConfigId(env),
        metadata: {
          user: this.input?.user?.docId as string,
          payout: this.input?.term?.payout || 0,
          offer: JSON.stringify(this.input?.offer)
        }
      })

    return res
  }

  //TODO - remove - this doesnt work for some reason
  clear() {
    this.paymentElement.elements.getElement('cardNumber')?.clear()
    this.paymentElement.elements.getElement('cardExpiry')?.clear()
    this.paymentElement.elements.getElement('cardCvc')?.clear()
  }

  async cancel() {
    await this.stripeService.cancel(this.input?.offer as ProductPricingOffer, this.input?.consumableDocId as string)
    await this.dismiss()
  }

  async dismiss() {
    console.log('dismissing payment dialog')
    this.utilityService.willDismiss()
    await this.stripeService.dismissPaymentModal()
  }

  async savePaymentInfo(paymentIntent: PaymentIntent) {
    await this.userService.setStripePaymentInfoSaved(true)
  }

  selectOfferToRedeem(offer: UserPortalOffer) {
    // console.log('selectOfferToRedeem', offer)
    const product = this.input?.offer as ProductPricingOffer
    console.log('selectOfferToRedeem', this.input)
    const basePrice = (product.specialPrice || product.priceAsNumber) as number
    const currSelection = this.selectedPortalOffer$.value
    if (currSelection?.name === offer.name) {
      this.amountToPay$.next(this.applyDiscount(basePrice))
      this.selectedPortalOffer$.next(null)
    }
    else {
      const offerValue = offer.spec.value as number
      this.amountToPay$.next(this.applyDiscount(basePrice - offerValue))
      this.selectedPortalOffer$.next(offer)
    }
  }

  offerSelected(event: any) {
    // console.log('offerSelected', event)
    this.selectOfferToRedeem(event.target.value)
  }
  
  async processPurchase(purchasePrice: number, amountToPay: string) {
    //amountToPay is the string $9.99
    let amountToCharge = amountToPay
      ? (typeof amountToPay === 'string' 
        ? Number(amountToPay.substring(1))
        : amountToPay) 
      : 0
    const actualPrice = this.input?.offer.specialPrice || this.input?.offer.priceAsNumber
    const seedCredits = this.input?.offer.seedCredits
    const summary = this.ledgerService.getSeedTypeSummary(seedCredits)
    const offer = await firstValueFrom(this.selectedPortalOffer$)
    console.log('processPurchase', { purchasePrice, actualPrice, amountToCharge, seedCredits })
   
    if (amountToCharge > 0) {
      // stripe API expects an integer number of pennies 
      try {
        // if an offer has been applied, we need to update the paymentIntent with the actual amount
        // Floating point weirdness means amount might sometimes comes in as
        //14.989999999999998 instead of 14.99. Massage it appropriately
        amountToCharge = parseFloat(amountToCharge.toFixed(2))
        if(amountToCharge != actualPrice ) {
          //sanity check
          if(!this.paymentIntentId)
            throw new Error(`Could not find paymentIntentId while updating payment intent`)
          // Need to convert the amount to pennies for Stripe API to work
          const updateRes = await this.stripe.paymentIntents.update(this.paymentIntentId, {amount: Math.floor(amountToCharge * 100)})
          console.log('Updated payment intent', updateRes)
        }
        // we send the actualPrice instead of the amount billed to the collectPayment method
        // as we want the ledger to reflect the price debited including
        // offers. the confirmPayment API called from collectPayment does not
        // use the parameter but relies on the paymentIntent
        const result = await this.collectPayment()
        if (result?.paymentIntent?.status === 'succeeded') {
          await this.completeSuccessfulPurchase(result?.paymentIntent, this.input?.offer.seedCredits, amountToCharge, offer)
          await this.dismiss()
          this.userService.paymentCompleted$.next(summary) // Trigger dismiss of purchase dialog
        }
      }
      catch (e:any) {
        console.error('Payment Failed', e)
        await this.utilityService.notify({ 
          header: 'Payment Failed', 
          message: e.message })
        return // early
      }
    }
    else {
      await this.completeSuccessfulPurchase(null, seedCredits, amountToCharge, offer)
      await this.stripeService.noPayment(this.input?.consumableDocId as string)
      await this.dismiss()
      this.userService.paymentCompleted$.next(summary) // Trigger dismiss of purchase dialog
    }    
  }

  async completeSuccessfulPurchase(intent: PaymentIntent | null, seedCredits: any, amountToCharge: number, offer: UserPortalOffer | null) {
    const group = this.groupService.currentUserGroup()
    await this.updateLedgerWithDebit(seedCredits, amountToCharge, offer, group)
    await this.stripeService.executeStripeSuccessLogic(this.input as StripeInputInterface, intent)
    await this.offerService.redeemUserPortalOffer(offer)
    console.log('Purchase successful', amountToCharge, intent)
  }

  async updateLedgerWithDebit(
      seedCredits: any, 
      purchasePrice: number, 
      offer: UserPortalOffer | null,
      group: Group | null) {

    const summary = this.ledgerService.getSeedTypeSummary(seedCredits)
    let description = `Purchased${ offer ? ' with $' + offer.spec.value + ' offer' : ''}: ${summary}`
    const term = AFFILIATE_TERMS.find(t => t.name === group?.affiliateTerms)
    let discountAmount, payoutAmount
    if (group && group.affiliateTerms && group.approvedAt) {
      let text = ''
      if (term?.discount) {
        text = `Discount: ${term.discount * 100}%`
        const originalPrice = this.input?.offer.priceAsNumber as number
        discountAmount = originalPrice * term.discount
      }
      if (term?.payout) {
        text = text + `Payout: ${term.payout}`
        payoutAmount = purchasePrice * term.payout
      }
      description += `, Affiliate Terms: ${text}`
    }
    const ledgerEntry: LedgerEntry = {
      createdAt: new Date(),
      userId: this.input?.user.docId as string,
      type: 'debit',
      source: 'purchase',
      amount: purchasePrice < 0 ? 0.0 : purchasePrice,
      credits: seedCredits,
      offer: { docId: offer?.docId, name: offer?.name, value: offer?.spec.value },
      description
    }
    // Add debit for the purchasePrice to the user ledger
    await this.userService.addUserLedgerEntry(ledgerEntry)

    // Add a group ledger entry
    if (group) {    
      const groupLedgerEntry: GroupLedgerEntry = {
        createdAt: ledgerEntry.createdAt,
        type: 'debit',
        source: 'purchase',
        userId: ledgerEntry.userId,
        amount: ledgerEntry.amount,
        payoutAmount,
        discountAmount,
        balance: group.balance,
        description
      }
      await this.groupService.addGroupLedgerEntry(groupLedgerEntry, group)
    }
  }

  async collectPayment() {
    if (this.paying() || this.checkoutForm.invalid) 
      return
    this.paying.set(true)
    try {
      const user = this.input?.user
      const res = await firstValueFrom(
        this.ngxStripe.confirmPayment({
          elements: this.paymentElement.elements,
          confirmParams: {
            receipt_email: user?.docId,
            payment_method_data: {
              billing_details: {
                name: user?.name,
                email: user?.docId
              }
            },
            //return_url: 'http://localhost:4200' + this.router.url,
            // TODO - issue - the expand parameter doesnt do anything
            expand: ['payment_intent.payment_method', 'payment_intent.charges']
          },
          redirect: 'if_required'
        }))
        //TODO - need to handle other states like processing. Unclear
        // how stripe handles delayes in card approval
        // See https://stripe.com/docs/payments/paymentintents/lifecycle#intent-statuses
        return res
    }
    finally {
      this.paying.set(false)
    }
  }
}