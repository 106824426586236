/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SharedUserService } from '@cheaseed/cheaseed-core';
import { map, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DevGuard implements CanActivate {
    private userService = inject(SharedUserService)

    constructor(
        @Inject('environment') private environment: { production: boolean },
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot)  {
            return this.userService.userLoggedIn$
                .pipe(
                    // tap(user => console.log('DevGuard user', user)),
                    // filter(user => !!user),
                    map(user => {                        
                        if (!this.environment.production && !user?.isAdminCertified) { // applicable only for the dev portal
                            // console.log('DevGuard state', state.url)
                            this.userService.devPasswordNeeded$.next(state.url)
                            return false
                        }
                        else 
                            return true            
                    })
                )
    }

}