import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cheaseed-purchase-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './purchase-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOptionComponent {

  @Input() item: any;
  @Input() currencyDigitInfo = '1.2-2';
  @Output() purchase = new EventEmitter<any>();

}
