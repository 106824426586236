import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingSpec, UserPortalOffer } from '@cheaseed/node-utils';

@Component({
  selector: 'cheaseed-offer-summary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferSummaryComponent {

  @Input() offers: UserPortalOffer[] = []
  @Input() pricingSpecs: PricingSpec[] = []

  getOfferDescription(offer: UserPortalOffer) {
    const scope = offer.spec.scope || []
    return offer.name + 
      (scope.length > 0 
        ? ', applies only to ' + scope.map(s => 
          {
            const spec = this.pricingSpecs.find(ps => ps.name === s.name)
            return spec?.title
          }).filter(str => !!str).join(', ')
        : '')
  }
}
