import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ANY_SEED_TYPE,
  CheaseedStripeService,
  CheaseedUser,
  ContentService,
  GroupService,
  SharedChatService,
  SharedUserService,
} from '@cheaseed/cheaseed-core';
import { MatButtonModule } from '@angular/material/button';
import {
  AffiliateTerms,
  BEHAVIOR_FOR_ADMIN_USER_ONLY,
  Group,
  PricingSpec,
  UserPortalOffer,
  todaystr,
} from '@cheaseed/node-utils';
import { BehaviorSubject, combineLatest, combineLatestWith, debounceTime, filter, map, withLatestFrom } from 'rxjs';
import {
  CheaseedStripeCardComponent,
  GlobalMessageComponent,
  LabelledSpinnerComponent,
} from '@cheaseed/shared/ui';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PortalOfferService, PortalUtilityService } from '@cheaseed/portal/util';
import { OfferSummaryComponent } from '../offer-summary/offer-summary.component';

@Component({
  selector: 'cheaseed-purchase',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    GlobalMessageComponent,
    OfferSummaryComponent,
    LabelledSpinnerComponent
  ],
  templateUrl: './purchase.component.html',
  styleUrl: './purchase.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseComponent {

  contentService = inject(ContentService)
  private conversationService = inject(SharedChatService)
  userService = inject(SharedUserService)
  private stripeService = inject(CheaseedStripeService)
  private utilityService = inject(PortalUtilityService)
  private router = inject(Router)
  offerService = inject(PortalOfferService)
  groupService = inject(GroupService)

  pricingSpecs$ = this.contentService.pricingSpecs$;
  selectedPricingSpec$ = new BehaviorSubject<PricingSpec | null>(null)

  applicableSpecs$ = this.contentService.pricingSpecs$
    .pipe(
      combineLatestWith(
        this.conversationService.nextConversation$,
        this.userService.userLoggedIn$,
        this.userService.isAdminRole$,
      ),
      debounceTime(300),
      map(([specs, chatName, user, isAdmin]) => {
        const today = todaystr();
        const inChatPlayer = this.router.url.includes('conversation');
        const chat = inChatPlayer
          ? this.contentService.getConversationNamed(chatName as string)
          : null;
        const seedType = chat?.seedType;
        // Exclude if startDate is in future
        specs = specs.filter((s) => !s.startDate || s.startDate <= today);
        // Exclude if endDate is in past
        specs = specs.filter((s) => !s.endDate || s.endDate > today);
        // Exclude if curent conversation seedType is not included in spec
        specs = specs.filter(
          (s) =>
            !s.seedCredits ||
            !seedType ||
            s.seedCredits[ANY_SEED_TYPE] > 0 ||
            s.seedCredits[seedType as string] > 0,
        );
        // Exclude if admin and not admin
        specs = specs.filter(
          (s) => !s.behaviors?.includes(BEHAVIOR_FOR_ADMIN_USER_ONLY) || isAdmin,
        );
        // console.log('applicableSpecs$', { chatName, specs });
        return specs as any[];
      }),
    );

    data$ = combineLatest([ 
      this.applicableSpecs$, 
      this.offerService.getAvailableUserPortalOffers(),
      this.userService.user$
    ])
      .pipe(
        map(([ specs, offers, user ]) => {
          const group = this.groupService.currentUserGroup()
          const terms = this.groupService.currentGroupTerms()
          return { specs, offers, user, terms, group }
        })
      )

    constructor() {
      this.selectedPricingSpec$
        .pipe(
          filter(spec => !!spec),
          combineLatestWith(this.userService.userLoggedIn$),
          withLatestFrom(this.offerService.getAvailableUserPortalOffers()),
          takeUntilDestroyed()
        )
        .subscribe(data => {
          const [[spec, user], offers] = data
          const group = this.groupService.currentUserGroup()
          console.log('selectedPricingSpec$', spec, user?.docId)
          if (spec && !user?.isAnonymousUser) {
            this.purchase(user as CheaseedUser, offers, spec, group)
            this.selectedPricingSpec$.next(null)
          }
        })
    }

  async purchase(
    user: CheaseedUser | null, 
    offers: UserPortalOffer[],
    item: PricingSpec,
    terms?: any) {
    if (!user?.isAnonymousUser) { 
      const { unlimitedPrepaid, affiliateTerms, prepaidBalance, pricePerCoach } = terms || {}
      if (!unlimitedPrepaid && (!prepaidBalance || prepaidBalance < (pricePerCoach || 0))) {
        await this.stripeService.purchaseItem({ 
            item, 
            offers, 
            term: affiliateTerms,
            user: user as CheaseedUser },
          CheaseedStripeCardComponent)
      }
      else {
        const message = this.contentService.getGlobal('portal.purchase.dialog.prepaid.message')
          || 'Your group has prepaid for use, so individual purchases are not currently enabled.'
        await this.utilityService.notify({ message })
      }
    }
    else {
      this.userService.requestLogin$.next(true)
      this.selectedPricingSpec$.next(item)
    }
  }
}
